import React from 'react'

import Home from './pages/Home'

import './css/style.scss'

function App(): JSX.Element {
    return (
        <div className="antialiased">
            <Home />
        </div>
    )
}

export default App
