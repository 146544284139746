/* eslint-disable max-len */
import React from 'react'

export default function Header(): JSX.Element {
    return (
        <div className="w-full z-30">
            <div className="max-w-6xl mx-auto px-5 sm:px-6">
                <div className="flex items-center justify-start h-24 md:h-20">
                    <div className="flex-shrink-0 mr-4">
                        <a href="https://monadial.com" className="flex logo-color">
                            <div className="flex-auto mr-4">
                                <span className="sr-only">Monadial</span>
                                <svg className="h-10 w-auto sm:h12 " viewBox="0 0 158 81">
                                    <path d="M0,9.53307692 L11.3070534,0 L48.3408294,40.5 L11.3070534,81 L0,71.4669231 L28.9028612,40.5 L0,9.53307692 Z M50.8817403,0 L70.3197085,21.2469231 L158,21.2469231 L158,34.3938462 L62.7604986,34.3938462 L39.5746869,9.53307692 L50.8817403,0 Z M62.7604986,46.6061538 L158,46.6061538 L158,59.7530769 L70.3197085,59.7530769 L50.8817403,81 L39.5746869,71.4669231 L62.7604986,46.6061538 Z" />
                                </svg>
                            </div>
                            <div className="flex-auto text-4xl font-semibold">monadial</div>
                        </a>
                    </div>
                    <div className="flex flex-grow justify-end" />
                </div>
            </div>
        </div>
    )
}
