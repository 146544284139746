import React from 'react'
import Footer from '../partials/Footer'
import Header from '../partials/Header'
import HomeHero from '../partials/HomeHero'

export default function Home(): JSX.Element {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden font-mono">
            {/*  Site header */}
            <Header />

            {/*  Main content */}
            <main className="flex-grow">
                <HomeHero />
            </main>

            {/*  Main content */}
            <Footer />
        </div>
    )
}
